import React, { useEffect, useRef, useState, useCallback } from 'react';
import { db, auth } from '../firebaseConfig';
import { doc,  getDoc, setDoc, updateDoc, getDocs, collection, query, where} from 'firebase/firestore';
import { storage } from '../firebaseConfig';
import { ref, getDownloadURL } from 'firebase/storage';
import Phaser from 'phaser';
import axios from 'axios';
import '../styles/GamePage.css';
import { useParams, useNavigate } from 'react-router-dom';

class JaKoScene extends Phaser.Scene {
  constructor(data) {
    super({ key: 'JaKoScene' });
    this.words = data.words || [];
    this.assets = data.assets || {};
    this.fallingWords = [];
    this.missedWords = [];
    this.wordSpeed = 0.40;
    this.wordDropDelay = 2000;
    this.wordsInSet = 5;
    this.correctInSet = 0;
    this.mode = data.mode;
    this.wordsDropped = 0;
    this.language = data.language;
    this.characterSteps = 0;
    this.characterDirection = 1; // 1 for right, -1 for left
    this.character = null; // Initialize as null
    this.object = null; // Initialize as null
    this.missedWordsRef = data.missedWordsRef;
    this.setCompletedFlag = false;
  }

  preload() {
    // Preload assets using Firebase storage URLs passed via props
    this.load.image('bamboo', this.assets.bamboo);
    this.load.image('crown', this.assets.crown);
    this.load.image('bambooLost', this.assets.bambooLost);
    this.load.image('crownLost', this.assets.crownLost);
    this.load.image('panda', this.assets.panda);
    this.load.image('tiger', this.assets.tiger);
    this.load.image('pandaMoving', this.assets.pandaMoving);
    this.load.image('tigerMoving', this.assets.tigerMoving);
    this.load.image('levelup', this.assets.levelup);
    this.load.audio('japanese', this.assets.japaneseAudio);
    this.load.audio('korean', this.assets.koreanAudio);
    this.load.audio('wrongans', this.assets.wrongAnsAudio);
  }



  create() {
    this.events = this.game.events;

    const bgAudioKey = this.language === 'japanese' ? 'japanese' : 'korean';
    this.bgMusic = this.sound.add(bgAudioKey, { volume: 0.5, loop: true });
    this.bgMusic.play();

    // Dynamically calculate positions based on input box
    this.updateCharacterAndObjectPositions();

    // Initialize character
    this.characterKey = this.language === 'japanese' ? 'panda' : 'tiger';
    this.characterMovingKey = this.language === 'japanese' ? 'pandaMoving' : 'tigerMoving';
    this.character = this.add.image(50, this.inputBoxY, this.characterKey)
        .setScale(0.125)
        .setOrigin(0.5, 1) // Anchor point to bottom center
        .setFlipX(false); // Start facing right for the first set

    // Initialize object
    this.objectKey = this.language === 'japanese' ? 'bamboo' : 'crown';
    this.object = this.add.image(this.scale.width - 50, this.inputBoxY, this.objectKey)
        .setScale(0.100)
        .setOrigin(0.5, 1); // Anchor point to bottom center

    this.characterDirection = 1; // Ensure it starts moving right

    // Start the first set after initialization
    this.startNewSet(true); // Pass `true` to indicate this is the first set
}

updateCharacterAndObjectPositions() {
  const canvasBounds = this.game.canvas.getBoundingClientRect();

  // Align the character and object to the bottom of the game container
  this.inputBoxY = this.scale.height; // Adjust this value if needed

}


startNewSet(isFirstSet = false) {
  if (!this.character || !this.object) {
    return;
  }

  this.correctInSet = 0;
  this.wordsDropped = 0;
  this.characterSteps = 0;

  // Destroy all existing falling words
  this.fallingWords.forEach((word) => word.destroy());
  this.fallingWords = [];

  // Move the object to the opposite side of the screen immediately
  const isCharacterOnLeft = this.character.x < this.scale.width / 2;
  this.object.setPosition(
    isCharacterOnLeft ? this.scale.width - 50 : 50,
    this.inputBoxY
  );

  // Adjust character direction for subsequent sets
  if (!isFirstSet) {
    this.characterDirection *= -1; // Reverse direction
    this.character.setFlipX(this.characterDirection === -1);
  } else {
    this.character.setFlipX(false);
  }

  // Reintroduce missed words first
  if (this.missedWords.length > 0) {
    this.reintroduceAllMissedWords(() => {
      this.generateSet();
    });
  } else {
    this.generateSet();
  }
}


update() {
  if (this.missedWordsRef.current.length === 3) {
    this.scene.pause();
    this.events.emit('game-over'); // Emit game-over event
    return;
  }

  if (this.gameOverFlag) return; // Stop further updates if game is over

  this.fallingWords.forEach((wordObj) => {
    if (wordObj) {
      wordObj.y += this.wordSpeed; // Move the word down
      if (wordObj.y > this.scale.height) {
        this.missWord(wordObj); // Trigger the missWord logic immediately
      }
    }
  });
}

reintroduceAllMissedWords(callback) {
  if (this.missedWords.length === 0) {
    if (callback) callback(); // Proceed if no missed words remain
    return;
  }

  const missedWord = this.missedWords.shift(); // Remove the first missed word

  if (missedWord) {
    this.generateSpecificWord(missedWord); // Generate the missed word on screen
  }

  // Recursively process the remaining missed words with a short delay
  this.time.addEvent({
    delay: 1000, // Adjust delay as needed
    callback: () => this.reintroduceAllMissedWords(callback),
  });
}

  generateSet() {
    for (let i = 0; i < this.wordsInSet; i++) {
      this.time.addEvent({ delay: this.wordDropDelay * i, callback: () => this.generateWord(), callbackScope: this });
    }
  }

  generateWord() {
    if (this.gameOver) {
      return; // Prevent generating words if the game is over
    }
  
    const randomIndex = Phaser.Math.Between(0, this.words.length - 1);
    const { original, translation } = this.words[randomIndex];
    const wordToShow = this.mode === "english" ? original : translation;
  
    const minX = this.scale.width * 0.20; // Restrict to 15% - 85% of the screen
    const maxX = this.scale.width * 0.80;
  
    const wordObj = this.add.text(
      Phaser.Math.Between(minX, maxX), // Keep words within this range
      -50,
      wordToShow,
      { fontSize: '32px', fill: '#ffffff' }
    );
  
    wordObj.setInteractive();
    wordObj.correctAnswer = this.mode === "english" ? translation.toLowerCase() : original.toLowerCase();
    wordObj.originalText = { original, translation };
  
    wordObj.on('pointerdown', () => {
      const textToSpeak = this.mode === "english" ? original : translation;
      this.speakWord(textToSpeak);
    });
  
    this.fallingWords.push(wordObj);
    this.wordsDropped++;
  }
  
  

  generateSpecificWord(wordData) {
    if (!wordData || !wordData.original || !wordData.translation) {
      console.error('Invalid word data in generateSpecificWord:', wordData);
      return;
    }
  
  
    const wordToShow = this.mode === "english" ? wordData.original : wordData.translation;
  
    const minX = this.scale.width * 0.20; // Keep words inside the visible area
    const maxX = this.scale.width * 0.80;
  
    const wordObj = this.add.text(
      Phaser.Math.Between(minX, maxX),
      -50,
      wordToShow,
      { fontSize: '32px', fill: '#ffffff' }
    );
  
    wordObj.setInteractive();
    wordObj.correctAnswer = this.mode === "english"
      ? wordData.translation.toLowerCase()
      : wordData.original.toLowerCase();
    wordObj.originalText = wordData;
  
    wordObj.on('pointerdown', () => {
      const textToSpeak = this.mode === "english" ? wordData.original : wordData.translation;
      this.speakWord(textToSpeak);
    });
  
    this.fallingWords.push(wordObj);
  }
  
  
  reintroduceMissedWord(wordData) {
  
    this.generateSpecificWord(wordData);
  }
  
  missWord(wordObj) {
    const wordData = wordObj?.originalText;
    if (!wordData || !wordData.original || !wordData.translation) {
      return;
    }
  
  
    // Check if the word is already in the missedWordsRef to avoid duplicates
    const isAlreadyMissed = this.missedWords.some(
      (missed) =>
        missed.translation === wordData.translation &&
        missed.original === wordData.original
    );
  
    if (!isAlreadyMissed) {
      this.missedWords.push(wordData); // Add to local missed words array
  
      // Avoid duplicate words in missedWordsRef
      if (!this.missedWordsRef.current.some(w => w.original === wordData.original && w.translation === wordData.translation)) {
        this.missedWordsRef.current.push(wordData);
      }
      

    }
  
    this.removeWord(wordObj); // Remove the word from the screen
  
    // Emit the life-lost event
    this.events.emit('life-lost');
  
    // Reintroduce the missed word immediately
    this.reintroduceMissedWord(wordData);
  }
  
    
  playWrongAnswerSound() {
    this.sound.play('wrongans', { volume: 0.2 });
  }

  showLevelUpImage(callback) {
  
    // Display the level-up image and set initial alpha to 0 (invisible)
    const levelUpImage = this.add.image(
      this.scale.width / 2,
      this.scale.height / 2,
      'levelup'
    )
      .setScale(0.35)
      .setOrigin(0.5)
      .setDepth(10)
      .setAlpha(0); // Start invisible for fade-in effect
  
    // Fade in the level-up image over 500ms
    this.tweens.add({
      targets: levelUpImage,
      alpha: { from: 0, to: 1 },
      duration: 500, // Duration of the fade-in animation
      ease: 'Linear',
      onComplete: () => {
    
  
        // Start moving the object after fade-in is complete
        const isCharacterOnLeft = this.character.x < this.scale.width / 2;
        const targetX = isCharacterOnLeft ? this.scale.width - 50 : 50;
  
        this.tweens.add({
          targets: this.object,
          x: targetX,
          duration: 400, // Match the movement duration
          ease: 'Linear',
          onComplete: () => {
    
          },
        });
      },
    });
  
    // Keep the level-up sign visible for 3 seconds before fading out
    this.time.delayedCall(3000, () => {
      this.tweens.add({
        targets: levelUpImage,
        alpha: { from: 1, to: 0 }, // Fade out
        duration: 500, // Duration of the fade-out animation
        ease: 'Linear',
        onComplete: () => {
          levelUpImage.destroy(); // Remove the level-up image from the screen
  
          if (callback) callback(); // Trigger the callback to proceed with the next set
        },
      });
    });
  }


  moveCharacter() {
    const stepSize = (this.scale.width - 100) / this.wordsInSet;
    const maxPosition = this.scale.width - 50;
  
    this.character.setTexture(this.characterMovingKey);
  
    // Move the character step by step
    this.tweens.add({
      targets: this.character,
      x: Phaser.Math.Clamp(this.character.x + (stepSize * this.characterDirection), 50, maxPosition),
      duration: 500,
      onComplete: () => {
        this.character.setTexture(this.characterKey); // Reset to idle texture after movement
        this.characterSteps++;
  
        // If the character completes the required steps
        if (this.characterSteps >= this.wordsInSet && !this.setCompletedFlag) {
          this.setCompletedFlag = true; // Prevent multiple triggers
          this.characterSteps = 0; // Reset the step count for the next set
  
     
  
          // Show the level-up image while waiting for the object to move
          this.showLevelUpImage(() => {
            // Flip the character direction once the object movement begins
            this.characterDirection *= -1;
            this.character.setFlipX(this.characterDirection === -1);
  
            // Smoothly move the object to the opposite side while the level-up sign is displayed
            const isCharacterOnLeft = this.character.x < this.scale.width / 2;
            this.tweens.add({
              targets: this.object,
              x: isCharacterOnLeft ? this.scale.width - 50 : 50,
              duration: 3000, // Duration matches the level-up sign duration (3 seconds)
              ease: 'Linear',
              onComplete: () => {
         
                this.events.emit('set-complete', true); // Emit event after the object reaches the other side
                this.setCompletedFlag = false; // Reset the flag for future sets
              },
            });
          });
        }
      },
    });
  }
  



  removeWord(wordObj) {
    const index = this.fallingWords.indexOf(wordObj);
    if (index > -1) {
      this.fallingWords.splice(index, 1); // Remove from array
    }
    wordObj.destroy(); // Remove from the screen
  }
  

  checkAnswer(input) {
    const normalizedInput = input.trim().toLowerCase();
    const matchedWord = this.fallingWords.find(wordObj => wordObj.correctAnswer === normalizedInput);
    if (matchedWord) {
      this.removeWord(matchedWord);
      this.correctInSet++;
      this.moveCharacter();
      this.missedWords = this.missedWords.filter(missed => missed.translation !== matchedWord.originalText.translation && missed.original !== matchedWord.originalText.original);

      if (this.correctInSet === this.wordsInSet && this.missedWords.length === 0) {
        this.events.emit('set-complete', true);
      }
      return true;
    }
    return false;
  }

  speakWord(text) {
    const utterance = new SpeechSynthesisUtterance(text);
    utterance.lang = this.language === 'japanese' ? 'ja-JP' : 'ko-KR';
    window.speechSynthesis.speak(utterance);
  }

}

const GamePage = () => {
  const { language, category, mode } = useParams();
  const navigate = useNavigate();
  const gameRef = useRef(null);
  const [wordPairs, setWordPairs] = useState([]);
  const [assets, setAssets] = useState(null);
  const [userInput, setUserInput] = useState('');
  const [lives, setLives] = useState(3);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(false);
  const sessionStartTime = useRef(Date.now());
  const gameOverFlag = useRef(false);
  const [backgroundImage, setBackgroundImage] = useState(null); 
  const missedWordsRef = useRef([]); // Ref to track missed words in real-time
  const [showMissedWords, setShowMissedWords] = useState(false);
  
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
    
        fetchWords(user.uid);  // Fetch words only when user is authenticated
      } else {
      
        navigate("/login");  // Redirect to login page if not authenticated
      }
    });
  
    return () => unsubscribe();  // Cleanup the listener
  }, [language, category, navigate]);
  
  const fetchWords = async (userId) => {
    try {
      const customCategoryRef = query(
        collection(db, "users", userId, "customSets"),
        where("setName", "==", category),
        where("language", "==", language)
      );
      const querySnapshot = await getDocs(customCategoryRef);
  
      if (!querySnapshot.empty) {
    
        const customData = querySnapshot.docs[0].data();
        const pairs = customData.wordPairs.map((pair) => ({
          original: pair.original,
          translation: pair.translation,
        }));
  
        setWordPairs(pairs);
        return;
      }
  
      // Alphabet-specific logic for Japanese (Hiragana, Katakana)
      if (language === "japanese" && (category === "Hiragana" || category === "Katakana")) {
        const jsonPath = `assets/vocabularies/${language}.json`;
        const fileRef = ref(storage, jsonPath);
        const fileURL = await getDownloadURL(fileRef);
  
        const response = await fetch(fileURL);
        if (!response.ok) {
          throw new Error(`Failed to load JSON. Status: ${response.status}`);
        }
  
        const data = await response.json();
        const alphabetData = data["Alphabet"];
        if (!alphabetData || !alphabetData[category]) {
          throw new Error(`Category ${category} not found in JSON`);
        }
  
        const selectedWords = alphabetData[category];
        const pairs = Object.entries(selectedWords).map(([original, translation]) => ({
          original,
          translation,
        }));
  
        setWordPairs(pairs);
        return;
      }
  
      // Alphabet-specific logic for Korean (Hangul)
      if (language === "korean" && category === "Hangul") {
        const jsonPath = `assets/vocabularies/${language}.json`;
        const fileRef = ref(storage, jsonPath);
        const fileURL = await getDownloadURL(fileRef);
  
        const response = await fetch(fileURL);
        if (!response.ok) {
          throw new Error(`Failed to load JSON. Status: ${response.status}`);
        }
  
        const data = await response.json();
        const alphabetData = data["Alphabet"];
        if (!alphabetData || !alphabetData["Hangul"]) {
          throw new Error(`Category Hangul not found in JSON`);
        }
  
        const selectedWords = alphabetData["Hangul"];
        const pairs = Object.entries(selectedWords).map(([original, translation]) => ({
          original,
          translation,
        }));
  
        setWordPairs(pairs);
        return;
      }
  
      // Standard category logic
    
      let apiUrl = `https://getallwords-2vscfffn7q-uc.a.run.app?language=${language}&category=${category}`;
  
      const response = await axios.get(apiUrl);
      const pairs = Object.entries(response.data).map(([original, translation]) => ({
        original,
        translation,
      }));
  
      setWordPairs(pairs);
    } catch (error) {

    }
  };
  
  // Fetch assets from Firebase Storage
  useEffect(() => {
    const loadAssets = async () => {
      try {
        const assetUrls = await Promise.all([
          getDownloadURL(ref(storage, 'assets/images/lives/bamboo.png')),
          getDownloadURL(ref(storage, 'assets/images/lives/crown.png')),
          getDownloadURL(ref(storage, 'assets/images/lives/bamboo_lost.png')),
          getDownloadURL(ref(storage, 'assets/images/lives/crown_lost.png')),
          getDownloadURL(ref(storage, 'assets/images/character/panda.png')),
          getDownloadURL(ref(storage, 'assets/images/character/tiger.png')),
          getDownloadURL(ref(storage, 'assets/images/character/panda_moving.gif')),
          getDownloadURL(ref(storage, 'assets/images/character/tiger_moving.gif')),
          getDownloadURL(ref(storage, 'assets/images/background/levelup.png')),
          getDownloadURL(ref(storage, 'assets/audio/japanese.mp3')),
          getDownloadURL(ref(storage, 'assets/audio/korean.mp3')),
          getDownloadURL(ref(storage, 'assets/audio/wrongans.mp3')),
          getDownloadURL(ref(storage, `assets/images/background/${language}_bg.jpg`)), // Fetch background
        ]);

        setAssets({
          bamboo: assetUrls[0],
          crown: assetUrls[1],
          bambooLost: assetUrls[2],
          crownLost: assetUrls[3],
          panda: assetUrls[4],
          tiger: assetUrls[5],
          pandaMoving: assetUrls[6],
          tigerMoving: assetUrls[7],
          levelup: assetUrls[8],
          japaneseAudio: assetUrls[9],
          koreanAudio: assetUrls[10],
          wrongAnsAudio: assetUrls[11],
        });
        setBackgroundImage(assetUrls[12]); // Set background image
      } catch (error) {
     
      }
    };

    loadAssets();
  }, [language]);


  const handleSetCompletion = useCallback((success) => {
    if (success) {
        const scene = gameRef.current.scene.getScene("JaKoScene");

        if (!scene || scene.setCompletedFlag) return; // Ensure it triggers only once
        scene.setCompletedFlag = true;

      

        setScore((prevScore) => {
       
            const newScore = prevScore + 1;

            return newScore;
        });

        // Call showLevelUpImage to display the level up image only
        scene.showLevelUpImage(() => {
       
            scene.wordsInSet += 2;
            scene.startNewSet();
            scene.setCompletedFlag = false; // Reset the flag for the next set
        });
    }
}, []);





  
  const updateHighScore = async (finalScore) => {
    if (!auth.currentUser) {
 
      return;
    }
  
    const userRef = doc(db, "users", auth.currentUser.uid);
  
    try {
      const userDoc = await getDoc(userRef);
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const currentHighScore = userData.highScore || 0;
  
   
        if (finalScore > currentHighScore) {
        
          await updateDoc(userRef, { highScore: finalScore });
        
        } else {
          
        }
      } else {
       
        await setDoc(userRef, { highScore: finalScore }, { merge: true });
      }
    } catch (error) {
      
    }
  };

  const handleLifeLost = useCallback(() => {
    setLives((prevLives) => {
      const newLives = prevLives - 1;
    
  
      if (newLives <= 0 && !gameOverFlag.current) {
        handleGameOver(); // Trigger game-over when lives reach 0
        return 0; // Ensure lives don’t go below 0
      }
      return newLives > 0 ? newLives : 0; // Prevent lives from going negative
    });
  }, []);
  
  const sessionSavedFlag = useRef(false);

  const saveSessionToFirebase = async (duration, finalScore) => {
    if (sessionSavedFlag.current) {
    
      return;
    }
    sessionSavedFlag.current = true;
  
    if (!auth.currentUser) {
 
      return;
    }
  
    try {
      const userId = auth.currentUser.uid;
      const userRef = doc(db, "users", userId, "stats", new Date().toISOString());
  
   
  
      // Remove duplicate words before saving to Firebase
      const uniqueMissedWords = Array.from(
        new Set(missedWordsRef.current.map((word) => JSON.stringify(word)))
      ).map((word) => JSON.parse(word));
  
      await setDoc(userRef, {
        language,
        category,
        typingMode: mode,
        score: finalScore,
        timeSpent: duration,
        missedWords: uniqueMissedWords.map(({ original, translation }) => ({
          original,
          translation,
        })), // Save only unique missed words
        date: new Date().toISOString(),
      });
  
     
    } catch (error) {
  
    }
  };
  
  
  
  const handleGameOver = () => {
    if (gameOverFlag.current) {
   
      return;
    }
    gameOverFlag.current = true;
  
    if (gameRef.current) {
      const scene = gameRef.current.scene.getScene('JaKoScene');
      scene.scene.pause(); // Pause the game
      scene.time.clearPendingEvents(); // Clear all scheduled events (e.g., word drops)
    }
  
    // Add remaining falling words to missedWordsRef
    if (gameRef.current) {
      const scene = gameRef.current.scene.getScene('JaKoScene');
      scene.fallingWords.forEach((wordObj) => {
        if (wordObj && wordObj.originalText) {
          const { original, translation } = wordObj.originalText;
          missedWordsRef.current.push({ original, translation });
        }
      });
    }
  
    
  
    setGameOver(true);
  
    setScore((prevScore) => {

  
      updateHighScore(prevScore);
  
      const sessionEndTime = Date.now();
      const duration = Math.floor((sessionEndTime - sessionStartTime.current) / 1000);
  
      saveSessionToFirebase(duration, prevScore); // Save session data with missed words
      return prevScore;
    });
  };
  
    
  useEffect(() => {
    if (gameRef.current) {
      const scene = gameRef.current.scene.getScene('JaKoScene');
  
      // Listen for life-lost event
      scene.events.on('life-lost', () => {
      
        handleLifeLost();
      });
    }
  }, [gameRef]);
  
  
   

  useEffect(() => {
    if (assets && wordPairs.length && !gameRef.current && Object.keys(assets).length > 0) {
      const config = {
        type: Phaser.CANVAS,
        width: window.innerWidth,
        height: Math.floor(window.innerHeight *0.825),
        parent: 'game-container',
        scene: new JaKoScene({ words: wordPairs, mode, language, assets, missedWordsRef }), // Pass missedWordsRef here
        scale: {
          mode: Phaser.Scale.ENVELOP,
          autoCenter: Phaser.Scale.CENTER_BOTH,
        },
        transparent: true,
      };
  
      const game = new Phaser.Game(config);
      game.events.once('ready', () => {
        const scene = game.scene.getScene('JaKoScene');
        if (scene) {
       
          scene.events.on('missed-word', (wordData) => {
        
            missedWordsRef.current.push(wordData); // Update ref immediately
          });
        }
      });
  
      game.events.on('set-complete', handleSetCompletion);
      game.events.on('life-lost', handleLifeLost);
  
      gameRef.current = game;
  
      return () => {
        if (gameRef.current) {
          gameRef.current.destroy(true);
          gameRef.current = null;
        }
      };
    }
  }, [assets, wordPairs, mode, language, handleSetCompletion, handleLifeLost]);    
  if (!assets) {
    return <div>Loading...</div>; // Show loading until assets are fetched
  }

  const handleInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleSubmit = () => {
    if (gameOver || !gameRef.current) {

      return; // Prevent input when the game is over
    }
  
    const scene = gameRef.current.scene.getScene('JaKoScene');
    const input = userInput.trim().toLowerCase();
    if (input) {
      const isCorrect = scene.checkAnswer(input);
      if (!isCorrect) {
        handleLifeLost();
      }
    }
    setUserInput('');
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const resetGame = () => {
    // Reset game state
    setGameOver(false);
    setLives(3);
    setScore(0);
    missedWordsRef.current = []; // Clear missed words
    
    if (gameRef.current) {
      // Destroy the current game instance
      gameRef.current.destroy(true);
      gameRef.current = null;
    }
  
    // Reinitialize Phaser game
    setTimeout(() => {
      const config = {
        type: Phaser.CANVAS,
        width: window.innerWidth,
        height: Math.floor(window.innerHeight * 0.825),
        parent: 'game-container',
        scene: new JaKoScene({ words: wordPairs, mode, language, assets, missedWordsRef }),
        scale: {
          mode: Phaser.Scale.ENVELOP,
          autoCenter: Phaser.Scale.CENTER_BOTH,
        },
        transparent: true,
      };
  
      const newGame = new Phaser.Game(config);
      newGame.events.on('set-complete', handleSetCompletion);
      newGame.events.on('life-lost', handleLifeLost);
  
      gameRef.current = newGame;
    }, 100);
  };
  

  const handlePlayAgain = () => {
    setGameOver(false);
    setLives(3); // Reset lives
    setScore(0); // Reset score
    missedWordsRef.current = []; // Clear missed words
    gameOverFlag.current = false; // Reset gameOverFlag
  
    if (gameRef.current) {
      const scene = gameRef.current.scene.getScene('JaKoScene');
      if (scene) {
        scene.events.off('set-complete'); // Remove set-complete event listener
        scene.events.off('life-lost'); // Remove life-lost event listener
      }
      gameRef.current.destroy(true);
      gameRef.current = null;
    }
  
    setTimeout(() => {
      const config = {
        type: Phaser.CANVAS,
        width: window.innerWidth,
        height: Math.floor(window.innerHeight *0.825),
        parent: 'game-container',
        scene: new JaKoScene({
          words: wordPairs,
          mode,
          language,
          assets,
          missedWordsRef,
        }),
        scale: {
          mode: Phaser.Scale.ENVELOP,
          autoCenter: Phaser.Scale.CENTER_BOTH,
        },
        transparent: true,
      };
  
      const newGame = new Phaser.Game(config);
      newGame.events.on('set-complete', handleSetCompletion);
      newGame.events.on('life-lost', handleLifeLost);
  
      gameRef.current = newGame;
    }, 100);
  };
  
  

  const handleViewMissedWords = () => {
    // Remove duplicate missed words before displaying them
    const uniqueMissedWords = Array.from(
      new Set(missedWordsRef.current.map((word) => JSON.stringify(word)))
    ).map((word) => JSON.parse(word));
  
    missedWordsRef.current = uniqueMissedWords; // Set unique values
    setShowMissedWords(true);
  };
  
  
  const handleSelectLanguage = () => {
    navigate(`/language-selection`);
  };

  const handleReturnToCategory = () => {
    navigate(`/category/${language}`);
  };

  const handleBack = () => {
    const routePath = `/rules/${language}/${category}/${mode}`;
    navigate(routePath);
  };

  return (
    <div
      className="page-container"
      style={{
        backgroundImage: `url(${backgroundImage})`, // Use the fetched background image
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="game-header">
      <button onClick={handleBack} className="back-button">←</button>
      <h2>Learn {category} in {language}</h2>
      <div className="score-lives">
        <div>
          {Array.from({ length: 3 }).map((_, index) => (
            <img
              key={index}
              src={
                lives > index
                  ? language === 'japanese'
                    ? assets.bamboo // Correctly use assets object
                    : assets.crown
                  : language === 'japanese'
                  ? assets.bambooLost // Correctly use assets object
                  : assets.crownLost
              }
              alt="Life Indicator"
              className="life-image"
            />
          ))}
        </div>
        <span>Score: {score}</span>
      </div>
    </div>
    <div id="game-container" style={{ width: '100%', height: `${window.innerHeight - 200}px`, backgroundColor: 'transparent' }} />

      {!gameOver && (
        <div className="input-container">
          <input 
            type="text" 
            value={userInput} 
            onChange={handleInputChange} 
            onKeyPress={handleKeyPress} 
            placeholder="Type the word here..." 
          />
          <button 
            onClick={handleSubmit} 
            className="button"
          >
            Submit
          </button>
          <button 
            onClick={resetGame} 
            className="button"
          >
            Reset
          </button>
        </div>
      )}

{gameOver && (
  <div className="game-over-overlay">
    <h3>Game Over!</h3>
    <p>Your Final Score: {score}</p>
    <button onClick={handleViewMissedWords} className="button">View Missed Words</button>
    <button onClick={handlePlayAgain} className="button">Play Again</button>
    <button onClick={handleSelectLanguage} className="button">Select Language</button>
    <button onClick={handleReturnToCategory} className="button">Return to Category</button>
  </div>
)}
{showMissedWords && (
  <div className="missed-words-overlay">
    <h3>Missed Words</h3>
    <ul>
      {missedWordsRef.current.map((word, index) => (
        <li key={index}>
          <strong>{word.original}</strong> - {word.translation}
        </li>
      ))}
    </ul>
    <button onClick={() => setShowMissedWords(false)} className="button">Back</button>
  </div>
)}

    </div>
  );
};

export default GamePage;