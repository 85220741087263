import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  query,
  where,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../firebaseConfig";
import { useNavigate, useParams } from "react-router-dom";
import "../styles/App.css";

const CustomCategoryPage = () => {
  const [categoryName, setCategoryName] = useState("");
  const [wordPairs, setWordPairs] = useState([{ original: "", translation: "" }]); // ✅ Default input for word pairs
  const [isBoxOpen, setIsBoxOpen] = useState(false);
  const [customCategories, setCustomCategories] = useState({});
  const [expandedSetId, setExpandedSetId] = useState(null);
  const [editMode, setEditMode] = useState({});

  const auth = getAuth();
  const currentUser = auth.currentUser;
  const navigate = useNavigate();
  const { language } = useParams();

  useEffect(() => {
    if (!currentUser || !language) return;

    const unsubscribe = onSnapshot(
      query(
        collection(db, "users", currentUser.uid, "customSets"),
        where("language", "==", language.toLowerCase())
      ),
      (snapshot) => {
        const fetchedCategories = {};
        snapshot.forEach((doc) => {
          const data = doc.data();
          fetchedCategories[doc.id] = { ...data, id: doc.id };
        });
        setCustomCategories(fetchedCategories);
      }
    );

    return () => unsubscribe();
  }, [currentUser, language]);

  const handleToggleExpand = (id) => {
    setExpandedSetId(expandedSetId === id ? null : id);
    setEditMode((prev) => ({ ...prev, [id]: false }));
    setWordPairs([...customCategories[id]?.wordPairs || []]);
  };

  const handleEditSet = (id) => {
    setEditMode((prev) => ({ ...prev, [id]: true }));
  };

  const handleSaveEdit = async (id) => {
    if (!id || !customCategories[id]) return;

    if (wordPairs.length === 0 || wordPairs.some(pair => !pair.original.trim() || !pair.translation.trim())) {
      alert("Word pairs cannot be empty. Please add valid words.");
      return;
    }

    try {
      await updateDoc(doc(db, "users", currentUser.uid, "customSets", id), {
        wordPairs,
      });
      alert("Set updated successfully!");

      setCustomCategories((prev) => ({
        ...prev,
        [id]: { ...prev[id], wordPairs },
      }));

      setEditMode((prev) => ({ ...prev, [id]: false }));
    } catch (error) {
      alert("An error occurred while updating the set. Please try again.");
    }
  };

  const handleDeleteCategory = async (id) => {
    try {
      await deleteDoc(doc(db, "users", currentUser.uid, "customSets", id));

    } catch (error) {
      alert("An error occurred while deleting the custom set. Please try again.");
    }
  };

  const handlePlayCategory = (id) => {
    navigate(`/mode/${language}/${encodeURIComponent(customCategories[id].setName)}`);
  };

  const handleWordChange = (index, field, value) => {
    const updatedPairs = [...wordPairs];
    updatedPairs[index][field] = value;
    setWordPairs(updatedPairs);
  };

  const addWordPair = () => {
    setWordPairs([...wordPairs, { original: "", translation: "" }]);
  };

  const removeWordPair = (index) => {
    const updatedPairs = wordPairs.filter((_, i) => i !== index);
    setWordPairs(updatedPairs);
  };

  const handleSaveCategory = async () => {
    if (!currentUser || !language) {
      alert("User is not authenticated or language is not selected!");
      return;
    }

    if (!categoryName.trim()) {
      alert("Category name cannot be empty!");
      return;
    }

    if (wordPairs.length === 0 || wordPairs.some(pair => !pair.original.trim() || !pair.translation.trim())) {
      alert("Word pairs cannot be empty. Please add valid words.");
      return;
    }

    try {
      const customSet = {
        setName: categoryName,
        wordPairs,
        language: language.toLowerCase(),
      };

      await addDoc(collection(db, "users", currentUser.uid, "customSets"), customSet);

      alert("Custom set saved successfully!");
      setIsBoxOpen(false);
      setCategoryName("");
      setWordPairs([{ original: "", translation: "" }]);
    } catch (error) {

      alert("An error occurred while saving. Please try again.");
    }
  };

  return (
    <div className="page-container">
      <button className="arrow-button" onClick={() => navigate(`/catorcust/${language}`)}>
        ←
      </button>

      <h1 className="heading">Custom Categories for {language}</h1>

      <div className="categories-list">
        {Object.keys(customCategories).length > 0 ? (
          Object.entries(customCategories).map(([id, details]) => (
            <div key={id} className="category-container">
              <div className="category-item">
                <button className="expand-button" onClick={() => handleToggleExpand(id)}>
                  {expandedSetId === id ? "▼" : "▶"}
                </button>
                <div className="set-name">{details.setName}</div>
                <button className="button delete-button" onClick={() => handleDeleteCategory(id)}>
                  Delete
                </button>
                <button className="button play-button" onClick={() => handlePlayCategory(id)}>
                  Play
                </button>
              </div>

              {expandedSetId === id && (
                <div className="word-pairs-container">
                  <ul>
                    {wordPairs.map((pair, index) => (
                      <li key={index} className="word-pair">
                        {editMode[id] ? (
                          <>
                            <input
                              type="text"
                              value={pair.original}
                              onChange={(e) => handleWordChange(index, "original", e.target.value)}
                            />
                            <input
                              type="text"
                              value={pair.translation}
                              onChange={(e) => handleWordChange(index, "translation", e.target.value)}
                            />
                            <button className="delete-word" onClick={() => removeWordPair(index)}>✕</button>
                          </>
                        ) : (
                          <span>{pair.original} - {pair.translation}</span>
                        )}
                      </li>
                    ))}
                  </ul>
                  {editMode[id] ? (
                    <div className="word-pair-actions">
                      <button className="button add-word" onClick={addWordPair}>+ Add Word</button>
                      <button className="button save-button" onClick={() => handleSaveEdit(id)}>Save</button>
                    </div>
                  ) : (
                    <button className="button edit-button" onClick={() => handleEditSet(id)}>Edit</button>
                  )}
                </div>
              )}
            </div>
          ))
        ) : (
          <p>No custom categories yet.</p>
        )}
      </div>

      {isBoxOpen && (
  <div className="new-set-form">
    <div className="form-header">
      <h3>Create New Set</h3>
      <button className="close-form-button" onClick={() => setIsBoxOpen(false)}>✕</button>
    </div>
    
    <input
      type="text"
      placeholder="Set Name"
      value={categoryName}
      onChange={(e) => setCategoryName(e.target.value)}
    />

    {/* Word Pairs Container with Scrolling */}
    <div className="word-pairs-scroll-container">
      <ul>
        {wordPairs.map((pair, index) => (
          <li key={index} className="word-pair">
            <input
              type="text"
              placeholder="Word"
              value={pair.original}
              onChange={(e) => handleWordChange(index, "original", e.target.value)}
            />
            <input
              type="text"
              placeholder="Translation"
              value={pair.translation}
              onChange={(e) => handleWordChange(index, "translation", e.target.value)}
            />
            <button className="delete-word" onClick={() => removeWordPair(index)}>✕</button>
          </li>
        ))}
      </ul>
    </div>

    {/* Buttons at the Bottom */}
    <div className="word-pair-actions">
      <button className="button add-word" onClick={addWordPair}>+ Add Word</button>
      <button className="button save-button" onClick={handleSaveCategory}>Save</button>
    </div>
  </div>
)}

<button
  className="floating-add-button"
  onClick={() => {
    setIsBoxOpen(true); 
    setCategoryName("");  // ✅ Reset category name
    setWordPairs([{ original: "", translation: "" }]);  // ✅ Ensure at least 1 empty word pair
  }}
>
  +
</button>

    </div>
  );
};

export default CustomCategoryPage;
